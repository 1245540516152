@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: white;
}


.content p {
  @apply my-2;
}

.content p strong {
  /* @apply border-solid; */
  /* @apply border-b-2; */
  /* @apply border-white; */
  @apply font-bold;
  /* @apply text-sherpa-blue-100; */
}

.content ul {
  @apply my-6;
}
